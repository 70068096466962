/*
UserAuthentication Component
*/
import React, {Component} from 'react';
import axios from 'axios';
import {FormErrors} from '../FormErrors/FormErrors';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class UserAuthentication extends Component{
    constructor(props){
        super(props);

        const params = new URLSearchParams(window.location.search) 
        let GET_params_user_mail = params.get('user_mail')
        let GET_params_uuid = params.get('uuid')

        let debugging = false

        let debugging_request_uuid = (["localhost", "127.0.0.1"].includes(window.location.hostname))?"ea353504-21f1-11ec-90a8-088eb0c9ddfa":"0d8fc4b7-3e03-11ec-803a-0242ac110006"

        this.state={
            did_not_change : true,
            email: (GET_params_user_mail != null)?GET_params_user_mail:debugging?"pmabiala@me.com":"",
            formErrors: {email: '', reference: ''},
            dossier:{uuidrequetes_log: ""},
            reference:(GET_params_uuid)?GET_params_uuid:debugging?debugging_request_uuid:"",
            access_denied:false, 
            debug:debugging
        }
    }

    componentDidMount = () =>{
        
    }

    clearForm = () => {
        //Reset state
        this.setState({email:"", reference:"", did_not_change:true});
    }

    /*  Prototype : microServiceDB
    *  Purpose   : Store data to remote database thru Express.js micro-service
    *  History   : Created on 2021-04-30
    */
    microServiceDB = async () => {
        this.requetes_service_db_url = (["localhost", "127.0.0.1"].includes(window.location.hostname))
                            ?'http://localhost:4001'
                            :`https://requetes.jesusestlareponsepourtous.org`;

                            
        //call requete-service with req-oth route
        //2DO DEBUG const serviceResult = await axios.post(`${this.requetes_service_db_url}/req-check-uuidrequete`,this.state);
        const serviceResult = await axios.post(`${this.requetes_service_db_url}/req-oth`,this.state);

        //Get service aknowledge before resetting state values.

        if (serviceResult.data.state === true) {
            const l_bool_user_authentified = 
                        ((serviceResult.data.items.length === 1) 
                        && (serviceResult.data.items[0].email.trim() == this.state.email.trim()) 
                        && (serviceResult.data.items[0].uuidrequetes.includes(this.state.reference.trim())));

            //Manage access denied label
            this.setState({access_denied : !l_bool_user_authentified});

            //Check authentification with couple email and UUID
            if (l_bool_user_authentified){
                this.setState({user_authentified:serviceResult.data.items[0]});
                
                //return authentification values
                this.props.onValidate(this.state.user_authentified);

                //Clear form
                this.clearForm();
            }
        }
        else{
            alert("Erreur:" + serviceResult.data.message);
        }
    }

    onFormSubmitHandler = async event => {
        event.preventDefault();
        this.microServiceDB();
    }  

    componentDidMount = () =>{
        this.handle_email = this.handle_email.bind(this);
        this.handle_reference = this.handle_reference.bind(this);
    }

    handle_email = (e) => {
        //Update name value from form field
        this.setState({did_not_change:false, email:e.target.value}, () => {this.validateField(e.target.name, e.target.value);});
    }

    handle_reference = (e) => {
        //Update name value from form field
        this.setState({did_not_change:false, reference:e.target.value}, () => {this.validateField(e.target.name, e.target.value);});
    }

    errorClass = (error) => {
        return(error.length === 0 ? '' : 'has-error');
    }

    validateForm = () => {
        this.setState({formValid: this.state.emailValid && this.state.passwordValid});
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let referenceValid = this.state.sujetValid;
      
        switch(fieldName) {
          case 'email':
            emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
            fieldValidationErrors.email = emailValid ? '' : ' est invalide';
            break;

          case 'reference':
            referenceValid = value.length >= 8;
            fieldValidationErrors.sujet = referenceValid ? '': ` est trop courte`;
            break;

          default:
            break;
        }

        this.setState({formErrors: fieldValidationErrors,
                        emailValid: emailValid,
                        referenceValid: referenceValid
                      }, this.validateForm);
    }

    formDisabled = () => {
        return (
            !(
                
            this.state.email.length > 0
            && this.state.formErrors.email.length == 0
            ));
    }

    render(){
        return(
            <div>
                <div className="panel panel-default">
                    <FormErrors formErrors={this.state.formErrors}/>
                </div>

                <ModalBody>
                    {this.state.debug && <h1>Debuging Mode</h1>}
                    <form id="requete-form" onSubmit={this.onFormSubmitHandler}> 
                        <div className={`form-group row ${this.errorClass(this.state.formErrors.email)}`}>
                            <label className="col-sm-2 col-form-label">Votre email:</label>
                            <div className="col-sm-4">
                                <input name="email" data-inputmask="'alias': 'email'" className="form-control" value={this.state.email} onChange={(e)=>{this.handle_email(e);}} type="email" placeholder="Votre adresse de courriel"/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Référence de dépôt:</label>
                            <div className="col-sm-4">
                                <input className="form-control" value={this.state.reference} onChange={(e)=>{this.handle_reference(e);}} type="text" placeholder="Référence du dossier reçue par courriel"/>
                                
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                    {/*<span className="depot-info">La référence de dépôt de requête vous a été adressée par courriel immédiatement après la soumission. Si vous l'avez oubliée cliquez le lien&nbsp;<a href="#">retrouver mes références</a>.</span><i></i>*/}
                                    <span className="depot-info">La référence de dépôt de requête vous a été adressée par courriel immédiatement après la soumission.</span><i></i>
                            </div>
                        </div>

                    </form>
                </ModalBody>
                
                <ModalFooter>
                    <label className="col-sm-4 col-form-label" hidden={!this.state.access_denied}>Les informations fournies ne correspondent à aucune requête !</label>
                    <Button color="primary" form="requete-form" disabled={this.formDisabled()}>Se connecter...</Button>
                    
                    <Button color="secondary" onClick={(e) => {this.clearForm();}} >Tout effacer</Button>
                </ModalFooter>
            </div>
        )
    }
}

export default UserAuthentication;