/*
AuthentView
*/

import React, {Component, useEffect, useState} from 'react';
import {useParams} from "react-router-dom"
import UserAuthentication from '../Components/UserAuthentication/UserAuthentication';
import { useNavigate } from 'react-router';

const debug = false

const AuthentView = () => {
    let [RequeteUUID, setRequeteUUID] = useState(null)
    let navigate = useNavigate();
    let {uuidrequetes} = useParams();
    console.log(`uuidrequetes = ${uuidrequetes}`)
    console.log("navigate =", navigate)

    useEffect(()=>{
        //Enforce cookies reading as page has been refreshed
        console.log(`uuidrequetes=${uuidrequetes}`)

        if (uuidrequetes != RequeteUUID){
            //Something has changed
            setRequeteUUID(uuidrequetes);
            
            //History.push to redirect page with user authentified from cookie
            console.log(`redirect to lounge with navigate(/userlounge/${uuidrequetes})`) 
            navigate(`/userlounge/${uuidrequetes}`);
        }
    })
    
    const OnUserConnectHandler = (user) => {
        console.log("OnUserConnectHandler user:", user);
        
        //Redirect top  route user/:uuid 
        console.log(`history.push(/userlounge/${user.uuidrequetes})`)   
        navigate(`/userlounge/${user.uuidrequetes}`); //DONE TWICE - 2 Be optimized !
    }
    
    const OnUserDisConnectHandler = () => {
        console.log("OnUserDisConnectHandler");
        //history.push(`/user`);
        setRequeteUUID(null)
    }

    //rendering
    return (
        <div className="authentication-page-container">
            {/* Full width main picture */}
            <div className="top-banner-image">
                <div className="nested-title">Mon espace personnel</div>
            </div>

            <div className="body-page-container">
                {/* Welcome Panel */}
                <div className="welcome-title">
                    <div>
                    Connexion à votre espace personnel
                    </div>
                </div>

                {/* Laïus introduction de la page*/}
                <div className="introduction-container">
                    <p>Veuillez entrer vos informations, afin de vous authentifier et accèder au suivi de votre requête de prière.
                    <br/>Authentifiez-vous&nbsp;!</p>
                </div>
                
                {debug &&
                <div>
                    <h1>AuthentView</h1>
                    <h2>uuidrequetes:{uuidrequetes}</h2>
                </div>
                }

                {!RequeteUUID &&
                <UserAuthentication
                    onValidate = {OnUserConnectHandler}
                />}

                {RequeteUUID &&
                <h2>User authentified !</h2>}
            </div>
        </div>
        );
}

export default AuthentView;